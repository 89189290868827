import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActiveTabIndicator from './active-tab-indicator';

const TabContainer = ({ color, children, style: customStyle, ...otherProps }) => {
  const [showIndicator, setShowIndicator] = useState(null);
  const [indicatorOffsetLeft, setIndicatorOffsetLeft] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);

  const setActiveTabRef = (tab) => {
    if (tab) {
      setShowIndicator(true);
      setIndicatorOffsetLeft(tab.offsetLeft);
      setIndicatorWidth(tab.clientWidth);
    } else {
      setShowIndicator(true);
    }
  };

  let hasActiveTab = false;
  const childrenWithRef = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const { isActive, ...otherChildProps } = child.props;
    if (isActive) {
      hasActiveTab = true;
    }
    return React.cloneElement(child, {
      color,
      ...otherChildProps,
      isActive,
      activeRef: isActive ? setActiveTabRef : null,
    });
  });

  if (!hasActiveTab && showIndicator) {
    setShowIndicator(false);
  }

  return (
    <div
      style={{
        overflowX: 'auto',
        position: 'relative',
        whiteSpace: 'nowrap',
        ...customStyle,
      }}
      {...otherProps}
    >
      {childrenWithRef}
      {showIndicator && (
        <ActiveTabIndicator color={color} left={indicatorOffsetLeft} width={indicatorWidth} />
      )}
    </div>
  );
};

TabContainer.defaultProps = {
  color: 'primary',
  style: {},
};

TabContainer.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  style: PropTypes.shape(),
};

export default TabContainer;
