import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Typography, withTheme } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';

const NavigationTabLink = ({
  activeRef,
  children,
  className,
  color,
  isActive,
  style: otherStyles,
  uiTheme,
  ...linkProps
}) => {
  return (
    <div ref={activeRef} style={{ display: 'inline-block' }}>
      <ClubLink {...linkProps}>
        <a>
          <Cushion
            className={className}
            vertical="medium"
            horizontal="small"
            responsive={[
              {
                minWidth: 'tab',
                props: {
                  vertical: 'small',
                  horizontal: 'medium',
                },
              },
            ]}
            style={{
              display: 'inline-block',
              outline: 'none',
              ...otherStyles,
            }}
          >
            <Typography
              preset="name"
              responsive={[{ minWidth: 'tab', props: { lineHeight: '42px' } }]}
              color={color}
              opacity={isActive ? 1 : 0.6}
            >
              {children}
            </Typography>
          </Cushion>
        </a>
      </ClubLink>
    </div>
  );
};

NavigationTabLink.defaultProps = {
  activeRef: null,
  className: null,
  color: 'primary',
  isActive: false,
  style: {},
};

NavigationTabLink.propTypes = {
  activeRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  isActive: PropTypes.bool,
  style: PropTypes.shape(),
  uiTheme: PropTypes.shape().isRequired,
};

export default withTheme(NavigationTabLink);
