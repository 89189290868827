import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@pitchero/react-ui';
import { get } from 'lodash';

const ActiveTabIndicator = ({ color, left, width, uiTheme }) => {
  const indicatorColor = get(uiTheme, `colors.${color}`, color);
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        transition: 'left .35s ease-in-out, width .25s ease-in-out',
        left: left + width / 2,
        marginLeft: -(width / 2),
        width,
      }}
    >
      <div
        style={{
          border: `solid 2px ${indicatorColor}`,
          borderRadius: 6,
          content: '',
          height: 0,
        }}
      />
      <div
        style={{
          border: 'solid 3px transparent',
          borderBottomColor: indicatorColor,
          marginLeft: -3,
          left: '50%',
          position: 'absolute',
          top: '-6px',
        }}
      />
    </div>
  );
};

ActiveTabIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  uiTheme: PropTypes.shape().isRequired,
};

export default withTheme(ActiveTabIndicator);
