import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cushion, Panel } from '@pitchero/react-ui';
import { closeLoginPanel } from 'store/auth/actions';
import { isLoggedIn, isLoginPanelOpen } from 'store/auth/selectors';
import LoginForm from './login-form';

const LoginPanel = ({ closePanel, isPanelOpen, loggedIn }) => {
  useEffect(() => {
    if (loggedIn) {
      closePanel();
    }
  }, [loggedIn]);

  return (
    <Panel isOpen={isPanelOpen} onClose={closePanel}>
      <Cushion horizontal="large" className="login-panel">
        <LoginForm />
      </Cushion>
    </Panel>
  );
};

const dispatchToProps = {
  closePanel: closeLoginPanel,
};

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
  isPanelOpen: isLoginPanelOpen(state),
});

LoginPanel.propTypes = {
  closePanel: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, dispatchToProps)(LoginPanel);
